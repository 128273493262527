.compalinace-page {
  font-family: Lato, sans-serif;
  font-style: normal;
}

.compalinace-content {
  background: #ffffff;
  padding: 32px 24px;
}

.content-container {
  margin: initial;
  max-width: initial;
}

.content-head {
  font-size: 24px;
  line-height: 30px;
  color: #213554;
  margin: 0;
  font-weight: 700;
}

.content-seprater {
  width: 28px;
  height: 5px;
  background-color: #213554;
  margin-top: 16px;
}

.updated-date {
  margin: 16px 0 0;
  color: #213554ab;
  font-weight: 700;
}

.content-text {
  font-size: 14px;
  line-height: 20px;
  color: #515978;
  margin: 16px 0 0;
}

.unorder-list {
  margin: 0;
}

.list-item {
  display: list-item;
  padding-left: 5px;
}
